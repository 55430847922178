import React, { useEffect, useState } from "react"
import Layer from "../components/Layer"
import HelpData from "../data/HelpData"
import HelpCard from "../components/HelpCard"
import "../css/style.css"
import Seo from "../components/Seo"


export default function Help() {
	const help = HelpData()
	const firstColumn = help.slice(0, 4)
	const secondColumn = help.slice(4)
	return (
		<>
			<Seo title="Help"/>
			<Layer>
				<main className="help">
					<div className="help__subheader">
						<div className="help__container">
							<div className="help__search-block block-search">
								<h2 className="block-search__title">How can we help?</h2>
							</div>
						</div>
					</div>
					<div className="help__links-block">
						<div className="help__container">
							<div className="help__body body-help">
								<div className="body-help__column  body-help__column_first">
									{firstColumn.map(({ name, list }, index) => (
										<HelpCard name={name} list={list} key={index} />
									))}
								</div>
								<div className="body-help__column body-help__column_second">
									{secondColumn.map(({ name, list }, index) => (
										<HelpCard name={name} list={list} key={index} />
									))}
								</div>
							</div>
						</div>
					</div>
				</main>
			</Layer>
		</>
	)
}