import { graphql, useStaticQuery } from "gatsby"

const HelpData = () => {
  const { allStrapiFaqGroup } = useStaticQuery(
    graphql`
      query {
        allStrapiFaqGroup {
          edges {
            node {
              Title
			        order_number
              dynamic_contents {
                page_title
                page_url
              }
            }
          }
        }
      }
    `
  )
  const sortedData = allStrapiFaqGroup.edges.map(item => item.node)
  sortedData.sort((a, b) => a.order_number - b.order_number)
  const helpData = sortedData.map(head => {
    return {
      name: head.Title,
      list: head.dynamic_contents.map(content => {
        return {
          name: content.page_title,
          url: `/${content.page_url}`,
        }
      }),
    }
  })
  return helpData
}

export default HelpData
